import moment from "moment";

const adminDashboardStatisticAlgorithm = (data, startDate, endDate) => {
  if (data.lenght === 0) return null;

  const clonedData = JSON.parse(JSON.stringify(data));

  const newData = clonedData.map(item => {
    return {
      ...item,
      createdAt: moment(item.createdAt).isBefore(startDate)
        ? new Date(
            moment(startDate)
              .startOf("month")
              .format("MM-DD-YYYY")
          ).toISOString()
        : item.createdAt
    };
  });

  const result = {};

  const filteredData = newData.filter(item =>
    moment(item.createdAt).isBetween(startDate, endDate, "day", "[]")
  );

  filteredData.forEach(uniqueItem => {
    const date = moment(uniqueItem.createdAt).format("DD.MM.YYYY");
    if (!result[date]) {
      result[date] = [];
    }
    result[date].push(uniqueItem.userId);
  });

  const sortedDates = Object.keys(result).sort((a, b) =>
    moment(a, "DD.MM.YYYY").diff(moment(b, "DD.MM.YYYY"))
  );

  return { statistic: result, dates: sortedDates, length: filteredData };
};

export default adminDashboardStatisticAlgorithm;
