import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { InputLabel, CheckboxInput } from "../../../../components";

import EditBenefitOptionsStyles from "./EditBenefitOptionsStyles";
import { useTranslation } from "react-i18next";

const EditBenefitOptions = ({
  tiers,
  availableTiers,
  disabled,
  handleChange,
  handleCheckUncheckAll
}) => {
  const { t } = useTranslation();
  const tierIds = tiers.map(tier => tier.id);

  const isSomeTierChecked = availableTiers.some(tierId =>
    tierIds.includes(tierId)
  );

  return (
    <EditBenefitOptionsStyles>
      <div className="checkboxContainer">
        <CheckboxInput
          isChecked={isSomeTierChecked}
          onChange={() => handleCheckUncheckAll(tierIds, isSomeTierChecked)}
        />
        <InputLabel
          label={`${isSomeTierChecked ? t("uncheck_all") : t("check_all")}`}
          margin="0 0 0 10px"
          shouldHaveColon={true}
        />
      </div>
      <div className="checkboxesContainer">
        {tiers.length > 0 ? (
          tiers.map(tier => (
            <div className="checkboxContainer" key={tier.id}>
              <CheckboxInput
                isChecked={availableTiers.includes(tier.id)}
                disabled={!disabled}
                onChange={() => handleChange(tier.id)}
              />
              <InputLabel
                label={`${tier.name} (${tier.tokens} Tokens)`}
                margin="0 0 0 10px"
                shouldHaveColon={false}
              />
            </div>
          ))
        ) : (
          <div className="checkboxContainer">
            <p className="message">{t("tiers_info")}</p>
          </div>
        )}
      </div>
    </EditBenefitOptionsStyles>
  );
};

EditBenefitOptions.propTypes = {
  tiers: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func
};

const mapStateToProps = state => {
  return {
    tiers: state.benefits.editPageState.tiers,
    disabled: state.benefits.editPageState.isBenefitGroupActive
  };
};

export default connect(mapStateToProps, null)(EditBenefitOptions);
