import styled from "styled-components";

const BenefitFormStyles = styled.div`
  .benefitsInfoTopContainer {
    .inputsContainer {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .labelStyles {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
  }

  .inputStyles {
    height: 46px;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
  }

  #expirationId {
    .react-dropdown-select {
      height: 46px !important;
      border-radius: 4px !important;
      border: solid 1px #d5dbdd !important;
      background-color: #ffffff !important;
      font-size: 14px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.71 !important;
      letter-spacing: -0.23px !important;
      color: #373737 !important;
      width: 100% !important;
      box-shadow: none !important;
      cursor: pointer !important;
      display: flex !important;
      align-items: center !important;

      .react-dropdown-select-content {
        height: 100% !important;
        display: flex !important;
        padding: 0 5px !important;
        align-items: center !important;
        flex-wrap: nowrap !important;
        text-overflow: ellipsis !important;
        overflow-x: hidden !important;
        width: 70px !important;
        white-space: nowrap !important;

        input {
          font-size: 12px !important;
          font-weight: normal !important;
          font-stretch: normal !important;
          font-style: normal !important;
          line-height: normal !important;
          letter-spacing: normal !important;
          color: #5d5d5d !important;
          overflow-x: scroll !important;
        }
      }

      .react-dropdown-select-dropdown-handle {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0 !important;
      }
    }
  }

  .benefitTagsContainer {
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
    height: 47px;

    button {
      font-size: 13px;
    }

    .benefitsTagsInputContainer {
      display: flex;
      align-items: flex-end;
      width: calc(100% - 120px);
      transform: translateX(70px);
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .showBenefitsTagsInputContainer {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .tagButton {
    display: flex;
    align-items: center;
    padding: 5px;
    background: #1568bf;
    margin: 5px;
    border-radius: 4px;
    height: 30px;

    .tagName {
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-right: 10px;
    }

    .removeTagButton {
      width: 18px;
      height: 20px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      background: #fff;
      color: black;
      border-radius: 4px;
      border: 1px solid #fff;
      outline: none;
      cursor: pointer;
    }
  }

  .shortNameDisclaimer {
    font-size: 11px;
    color: #5d5d5d !important;
  }

  .summary {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100px;
    overflow-y: auto;
    min-height: 100px;
    max-height: 100px;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    padding: 12px 14px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
    outline: none;
    resize: none;
  }

  .usageInstructions {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100px;
    overflow-y: auto;
    min-height: 100px;
    max-height: 100px;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    padding: 12px 14px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
    outline: none;
    resize: none;
  }

  .descriptionContainer {
    width: 100%;
    margin: 10px 0;

    .rdw-editor-wrapper {
      .rdw-editor-toolbar {
        .rdw-emoji-wrapper {
          .rdw-emoji-modal {
            left: -20px !important;
          }
        }
        .rdw-link-wrapper {
          .rdw-link-modal {
            font-size: 14px;
            .rdw-link-modal-label {
              font-size: 14px;
            }
          }
        }
      }
      .rdw-editor-main {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 150px;
        overflow-y: auto;
        min-height: 150px;
        border-radius: 4px;
        border: solid 1px #d5dbdd;
        background-color: #ffffff;
        padding: 12px 14px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: -0.23px;
        color: #373737;
        outline: none;
        resize: none;
        .DraftEditor-root {
          z-index: 0;
        }
      }
    }
  }
  .publishBenefitLink {
    color: #1e3c72;
    cursor: pointer;
    font-size: 15px;
  }
`;

export default BenefitFormStyles;
