import styled from "styled-components";

const PreviewHRBenefitStyles = styled.section`
  height: 100vh;

  .previewHrBenefitsContainer {
    width: calc(100% - 210px);
    margin-left: 210px;
    background: whitesmoke;
    min-height: 100vh;
    padding: 0 30px 30px 30px;

    header {
      padding: 0;
    }
  }

  .previewHrBenefitsContent {
    padding-bottom: 20px;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }

  .canvas-padding {
    padding: 80px;
  }

  .html2canvas-container {
    width: 3000px !important;
    height: 3000px !important;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
  }

  .submitContainer {
    display: flex;
  }
`;

export default PreviewHRBenefitStyles;
