import adminActionTypes from "../../constants/actionTypes/admin/admin.constants";
import { updateObject } from "../../services/updateObject";

const initialState = {
  filters: { page: 1, perPage: 10, type: [] },
  adminSingleUserData: null
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminActionTypes.SET_ADMIN_USER_FILTERS:
      return setAdminFilters(state, action);
    case adminActionTypes.SET_ADMIN_USER_DATA:
      return setAdminUserData(state, action);
  }

  return state;
};

const setAdminFilters = (state, action) => {
  return updateObject(state, { filters: action.payload });
};

const setAdminUserData = (state, action) => {
  return updateObject(state, { adminSingleUserData: action.payload });
};

export default adminReducer;
