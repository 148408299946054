import styled from "styled-components";

const BenefitGroupBenefitPreviewListStyles = styled.section`
  max-width: 1064px;

  // Styles for single item
  .benefitGroupPreviewItemWrapper {
    display: flex;
    flex-direction: column;

    &:first-child {
      border-top: 0.5px solid #f0f0f0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .benefitGroupPreviewItem {
      width: 100%;
      padding: 15px 15px 20px 15px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.11);
      border: solid 1px #e6eaee;
      background-color: #ffffff;
      margin-bottom: 12px;
      position: relative;

      .benefitGroupPreviewItemTitleBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          width: 39%;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          color: #354052;
        }

        .remainingQuantity {
          font-weight: 500;
          font-size: 13px;
          color: #616161;
        }
      }

      .benefitDescription {
        margin-top: 15px;
        word-wrap: break-word;
        color: #000000;
        font-size: 14px;
      }

      .benefitSummary {
        margin-top: 15px;
        word-wrap: break-word;
        color: #000000;
        font-size: 14px;
      }

      .benefitExpirationAndTokens {
        display: flex;
        align-items: center;
        margin-top: 12px;
      }

      .duration {
        min-width: 11%;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.2px;
        color: #373737;
        opacity: 0.8;
        margin-right: 2px;
      }

      .tokenContainer {
        display: flex;
        align-items: center;
      }

      .tokens {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.2px;
        color: #373737;
        opacity: 0.8;
        margin-left: 4px;
      }
    }
  }
`;

export default BenefitGroupBenefitPreviewListStyles;
