import React from "react";
import AdminUserHistoryContentStyles from "./AdminUserHistoryContentStyles";
import { AdminUserHistoryHeader } from "./AdminUserHistoryHeader";
import { AdminUserHistoryBody } from "./AdminUserHistoryBody";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { useDispatch } from "react-redux";
import { setAdminFilters } from "../../../../actions/admin/admin.actions";

export const AdminUserHistoryContent = props => {
  const dispatch = useDispatch();
  const { adminSingleUserData, filters } = props;

  const handlePageChange = page => {
    dispatch(setAdminFilters({ ...filters, page }));
  };

  return (
    <>
      <AdminUserHistoryBody
        user={adminSingleUserData.user}
        listOfData={adminSingleUserData.infoData}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 10
        }}
      >
        <Pagination
          currentPage={filters.page}
          totalSize={adminSingleUserData.page.totalSize}
          sizePerPage={filters.perPage}
          changeCurrentPage={handlePageChange}
          theme="border-bottom"
        />
      </div>
    </>
  );
};
