import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import Pagination from "react-pagination-js";
// components
import {
  Header,
  Card,
  BenefitCard,
  SearchInput,
  MultiselectInput,
  SelectInput,
  LocationsMultiselectInput
} from "../../../components";
// styles
import HrBenefitsStyles from "./HrBenefitsStyles";
import { override } from "../../../services/loadingOverride.styles";
// services
import * as actionCreators from "../../../actions/hr/benefits.actions";
import * as api from "../../../services/api/hr/benefits.service";

import * as employeeService from "../../../services/api/employee/employeeBenefits.services";
import * as benefitTagService from "../../../services/api/admin/benefitTags.service";
import * as benefitGroupService from "../../../services/api/admin/benefitGroups.service";
import { isEmpty } from "../../../services/general.utils";
import { useTranslation } from "react-i18next";
import { getCities } from "../../../services/api/hr/employees.service";
import { setRemoteFilter } from "../../../actions/employee/employeeBenefits.actions";

const LIMIT = 8;

const HrBenefits = ({
  benefitGroups,
  count,
  page,
  setBenefitGroups,
  setPage,
  setCategories,
  categories,
  setBenefitTags,
  benefitTags,
  setEnabledBenefitGroup,
  enabledBenefitGroup,
  setKeywordSearch,
  keywordSearch,
  setChosenCategories,
  chosenCategories,
  chosenCategoryIds,
  setChosenBenefitTags,
  chosenBenefitTags,
  chosenBenefitTagIds,
  resetBenefitFiltersToDefault
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const isCardClicked = useRef(false);
  const areFiltersLoaded = useRef(false);
  const isMounted = useRef(true);

  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const [citiesPage, setCitiesPage] = useState(1);
  const [citiesSearchQuery, setCitiesSearchQuery] = useState("");
  const [isRemoteLoc, setIsRemoteLoc] = useState(false);

  const { cities, chosenCities, chosenCityIds } = useSelector(
    state => state.benefits.filters
  );

  const benefitGroupAvailabilityStatusOptions = [
    { id: 1, name: t("enabled"), value: true },
    { id: 2, name: t("disabled"), value: false }
  ];

  const handleBenefitGroupAvailabilityFilterChange = option => {
    if (isEmpty(option)) {
      setEnabledBenefitGroup("");
    }
    if (!isEmpty(option)) {
      setEnabledBenefitGroup(option);
    }

    setPage(1);
  };

  const handleSearch = searchText => {
    setKeywordSearch(searchText);
    setPage(1);
  };

  const handleFetchAfterSearch = () => {
    fetchData();
  };

  /**
   * Handles select of element from multiselect.
   * Sets selected elements' ids to state.
   * @param {Category} options - Selected options, emmited from multiselect.
   */
  const handleSetChosenCategories = options => {
    let selectedCategoryIds = [];

    if (options && options.length) {
      selectedCategoryIds = options.map(category => category.id);
    }

    setChosenCategories(options, selectedCategoryIds);
    setPage(1);
  };

  /**
   * Handles select of element from multiselect.
   * Sets selected elements' ids to state.
   * @param {Tag} options - Selected options, emmited from multiselect.
   */
  const handleSetChosenBenefitTags = options => {
    let selectedBenefitTagIds = [];

    if (options && options.length) {
      selectedBenefitTagIds = options.map(tag => tag.id);
    }

    setChosenBenefitTags(options, selectedBenefitTagIds);
    setPage(1);
  };

  const fetchFilters = async () => {
    await fetchCategories();
    await fetchBenefitTags();

    areFiltersLoaded.current = true;
  };

  const fetchCategories = async () => {
    const categoriesResponse = await employeeService.getCategories();

    if (categoriesResponse.hasError) {
      return toast.error(
        categoriesResponse.errorMessage
          ? categoriesResponse.errorMessage
          : t("failed_to_get_categories")
      );
    }

    setCategories(categoriesResponse.categories);
  };

  const fetchBenefitTags = async () => {
    const benefitTagsData = await benefitTagService.getBenefitTags();

    if (benefitTagsData.hasError) {
      return toast.error(
        benefitTagsData.errorMessage
          ? benefitTagsData.errorMessage
          : t("failed_to_get_benefit_tags")
      );
    }

    setBenefitTags(benefitTagsData.tags);
  };

  const fetchData = async () => {
    const response = await api.getBenefitGroups({
      limit: LIMIT,
      page,
      searchQuery: keywordSearch,
      categoryIds: chosenCategoryIds,
      tagIds: chosenBenefitTagIds,
      enabledBenefitGroup: enabledBenefitGroup.value,
      cityIds: chosenCityIds,
      isRemoteLoc
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_benefit_groups")
      );
    }

    if (isMounted.current) {
      setBenefitGroups(response.groups, response.count);
      setIsLoading(false);
    }
    // setTimeout delays animation until content is ready to be displayed (set to state)
    setTimeout(() => {
      const list = document.getElementById("benefitGroupList");
      if (list && isMounted.current) {
        list.style.opacity = 1;
      }
    }, 100);
  };

  /**
   * If benefit group is marked as new, sends request to DB for updating status.
   * @param {number} benefitGroupId
   * @param {number} isBenefitGroupNew
   */
  const handleBenefitCardClick = async (benefitGroupId, isBenefitGroupNew) => {
    isCardClicked.current = true;
    if (isBenefitGroupNew) {
      await benefitGroupService.markNewBenefitGroupAsSeen(benefitGroupId);
    }

    history.push(`/benefits/edit/${benefitGroupId}`);
  };

  /**
   * Handles changing of page.
   * Sets page to store.
   * Scrolls window to top.
   * @param {number} page
   */
  const handleSetPage = page => {
    setPage(page);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 200);
  };

  useEffect(() => {
    fetchData();
    fetchFilters();
  }, [page, chosenCityIds]);

  useEffect(() => {
    if (areFiltersLoaded.current === true) fetchData();
  }, [
    chosenCategoryIds,
    chosenBenefitTagIds,
    enabledBenefitGroup,
    keywordSearch
  ]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      if (!isCardClicked.current) {
        resetBenefitFiltersToDefault();
      }
    };
  }, []);

  const refetchCities = async () => {
    let currentPage = citiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setCitiesPage(newPage);

    const response = await getCities(citiesSearchQuery, newPage);

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    let newCities = [];
    setTimeout(() => {
      newCities = [...cities, ...response.cities];
      setTimeout(() => {
        dispatch(actionCreators.setHrBenefitGroupCities(newCities));
      }, 100);
    }, 100);
  };

  const fetchCities = async () => {
    const response = await getCities(citiesSearchQuery, citiesPage);

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    const remoteOption = { id: 0, name: "Remote" };
    const cities = [remoteOption].concat(response.cities);

    dispatch(actionCreators.setHrBenefitGroupCities(cities));
    setTotalPages(response.pages);
  };

  const handleInputChange = newValue => {
    if (newValue === "") setIsRemoteLoc(false);

    setCitiesPage(1);
    setCitiesSearchQuery(newValue);

    return newValue;
  };

  const handleSelect = (options, action) => {
    setPage(1);
    let selectedCityIds = [];

    if (options && options.length) {
      selectedCityIds = options.map(city => city.id);
    }

    if (action.action === "select-option" && action.option.name === "Remote") {
      setIsRemoteLoc(true);
    }

    if (
      action.action === "remove-value" &&
      action.removedValue.name === "Remote"
    ) {
      setIsRemoteLoc(false);
    }

    dispatch(actionCreators.setHrBenefitGroupChosenCityIds(selectedCityIds));
    dispatch(actionCreators.setHrBenefitGroupChosenCities(options));
  };

  useEffect(() => {
    fetchCities();
  }, [citiesPage, citiesSearchQuery]);

  return (
    <HrBenefitsStyles>
      <div className="hrBenefitsContainer">
        <Header headerTitle={t("Benefits")} />
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <div className="contentSection">
            <Card
              id="benefitGroupList"
              padding="30px 30px 0 30px"
              boxShadow="
									0 2px 4px -1px rgba(0, 0, 0, 0.2),
									0 1px 10px 0 rgba(0, 0, 0, 0.12),
									0 4px 5px 0 rgba(0, 0, 0, 0.14)
								"
            >
              <div className="searchWrapper">
                <SearchInput
                  fetchData={handleFetchAfterSearch}
                  setSearch={handleSearch}
                  search={keywordSearch}
                  customClass="benefitsSearch"
                  setPagginationPage={setPage}
                  useTimeout={false}
                />
              </div>
              <div className="filtersHeader">
                <div className="filters">
                  <MultiselectInput
                    name="categories"
                    options={categories}
                    placeholder={t("filter_by_group_category")}
                    handleChange={option => handleSetChosenCategories(option)}
                    selectedValues={chosenCategories}
                  />
                  <MultiselectInput
                    name="tags"
                    options={benefitTags}
                    placeholder={t("filter_by_benefit_tags")}
                    handleChange={option => handleSetChosenBenefitTags(option)}
                    selectedValues={chosenBenefitTags}
                  />
                  <SelectInput
                    name="enabledBenefitGroup"
                    options={benefitGroupAvailabilityStatusOptions}
                    handleChange={option =>
                      handleBenefitGroupAvailabilityFilterChange(option)
                    }
                    value={enabledBenefitGroup}
                    clearable={true}
                    customClass="hrBenefitsSearch"
                    customHeight
                    placeholder={t("filter_by_status")}
                    minWidth="180px"
                    isClearable
                  />
                  <div
                    className="filtersListContainer"
                    style={{ width: "200px" }}
                  >
                    <LocationsMultiselectInput
                      name="cities"
                      options={cities}
                      selectedValues={chosenCities}
                      handleChange={handleSelect}
                      handleInputChange={handleInputChange}
                      shouldHaveFullWidth={true}
                      fetchMoreData={refetchCities}
                    />
                  </div>
                </div>
              </div>
              <div className="benefitList">
                {benefitGroups.map(benefitGroup => {
                  return (
                    <BenefitCard
                      benefitGroup={benefitGroup}
                      isHr
                      benefitDetails={benefitGroup}
                      benefitGroupId={benefitGroup.id}
                      key={benefitGroup.id}
                      onClick={() =>
                        handleBenefitCardClick(
                          benefitGroup.id,
                          benefitGroup.isBenefitGroupNew
                        )
                      }
                    />
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 0px 20px 0px"
                }}
              >
                <Pagination
                  currentPage={page}
                  totalSize={count}
                  sizePerPage={LIMIT}
                  changeCurrentPage={currentPage => handleSetPage(currentPage)}
                  theme="border-bottom"
                />
              </div>
            </Card>
          </div>
        )}
      </div>
    </HrBenefitsStyles>
  );
};

const mapStateToProps = state => {
  return {
    benefitGroups: state.benefits.benefitsPage.benefitGroups,
    categories: state.benefits.filters.categories,
    benefitTags: state.benefits.filters.tags,
    enabledBenefitGroup: state.benefits.filters.enabledBenefitGroup,
    keywordSearch: state.benefits.filters.keywordSearch,
    chosenCategories: state.benefits.filters.chosenCategories,
    chosenCategoryIds: state.benefits.filters.chosenCategoryIds,
    chosenBenefitTags: state.benefits.filters.chosenBenefitTags,
    chosenBenefitTagIds: state.benefits.filters.chosenBenefitTagIds,
    count: state.benefits.benefitsPage.count,
    page: state.benefits.benefitsPage.page
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setBenefitGroups: (benefitGroups, count) =>
      dispatch(actionCreators.setBenefitGroups(benefitGroups, count)),
    setCategories: categories =>
      dispatch(actionCreators.setCategories(categories)),
    setBenefitTags: tags => dispatch(actionCreators.setTags(tags)),
    setEnabledBenefitGroup: enabledBenefitGroup =>
      dispatch(actionCreators.setEnabledBenefitGroup(enabledBenefitGroup)),
    setKeywordSearch: keywordSearch =>
      dispatch(actionCreators.setKeywordSearch(keywordSearch)),
    setChosenCategories: (chosenCategories, chosenCategoryIds) =>
      dispatch(
        actionCreators.setChosenCategories(chosenCategories, chosenCategoryIds)
      ),
    setChosenBenefitTags: (chosenTags, chosenTagIds) =>
      dispatch(actionCreators.setChosenTags(chosenTags, chosenTagIds)),
    setPage: page => dispatch(actionCreators.setPage(page)),
    resetBenefitFiltersToDefault: () =>
      dispatch(actionCreators.resetBenefitFiltersToDefault())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HrBenefits);
