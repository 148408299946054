import React from "react";
import styled from "styled-components";

import { EmptyListIcon } from "../../assets/Icons";
import { useTranslation } from "react-i18next";

const ListPlaceHolderStyles = styled.div`
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .emptyText {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #919ba8;
    word-break: break-all;
  }
`;

const ListPlaceHolder = ({ text = "empty_list" }) => {
  const { t } = useTranslation();

  return (
    <ListPlaceHolderStyles>
      <EmptyListIcon />
      <p className="emptyText">{t(text)}</p>
    </ListPlaceHolderStyles>
  );
};

export default ListPlaceHolder;
