import styled from "styled-components";

const LocationsMultiselectInputStyles = styled.div`
  width: 100%;
  height: ${({ shouldHaveHight }) => shouldHaveHight && "27px"};

  .react-dropdown-select {
    min-height: 27px !important;
    max-width: ${({ shouldHaveFullWidth }) =>
      shouldHaveFullWidth ? "100%" : "202px"};
    height: 100% !important;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #979797;
    box-shadow: none !important;
    border-color: #979797 !important;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5d5d5d;
    display: flex;
    align-items: center;

    .react-dropdown__menu {
      position: absolute;
      z-index: 3;
    }

    .react-dropdown__control,
    .react-dropdown__control--is-focused,
    .react-dropdown__control--is-open {
      height: ${({ customInputFieldHeight }) => customInputFieldHeight};
      min-height: ${({ customHeight }) => customHeight} !important;
      width: 100%;
      border: 0;
      cursor: pointer;

      .react-dropdown__indicators {
        height: ${({ customHeight }) => customHeight};
        min-height: ${({ customHeight }) => customHeight} !important;
      }

      .react-dropdown__value-container
        .react-dropdown__value-container--has-value {
        height: ${({ customHeight }) => customHeight};
        min-height: ${({ customHeight }) => customHeight} !important;
      }

      .react-dropdown__indicator .react-dropdown__dropdown-indicator {
        height: ${({ customHeight }) => customHeight};
        min-height: ${({ customHeight }) => customHeight} !important;
      }
    }

    & > div {
      min-width: ${({ shouldHaveFullWidth }) =>
        shouldHaveFullWidth ? "100%" : "200px"};
    }
    /* overflow-x: hidden; */

    .react-dropdown-select-content {
      height: 100%;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      width: 70px;
      white-space: nowrap;

      input {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5d5d5d;
      }
    }

    .react-dropdown-select-dropdown {
      max-height: 150px;
    }

    .itemContainer {
      &:hover {
        background: #1568bf;
        .dropDownItem {
          color: #fff;
        }
      }

      .dropDownItem {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5d5d5d;
      }
    }
  }
`;

export default LocationsMultiselectInputStyles;
