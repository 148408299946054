import React from "react";

import {
  InputLabel,
  TextAndLine,
  CheckboxInput
} from "../../../../../../components";
import { Voucher } from "../../../../../../models/domain";

import { TokenIcon } from "../../../../../../assets/Icons";

import { VouchersStyles } from "./voucher.styles";
import { useTranslation } from "react-i18next";

const Vouchers = ({
  values,
  setFieldValue,
  errors,
  touched,
  isAdmin = false
}) => {
  const { t } = useTranslation();

  const addNewVoucher = () => {
    setFieldValue("vouchers", [...values.vouchers, new Voucher()]);
  };

  const handleNameChange = (e, index) => {
    const updatedVouchers = values.vouchers.map((voucher, i) => {
      if (i === index) {
        return new Voucher({ ...voucher, name: e.target.value });
      } else {
        return voucher;
      }
    });
    setFieldValue("vouchers", updatedVouchers);
  };

  const handlePriceChange = (e, index) => {
    let inputValue = e.target.validity.valid ? parseInt(e.target.value, 0) : "";
    const updatedVouchers = values.vouchers.map((voucher, i) => {
      if (i === index) {
        return new Voucher({ ...voucher, price: inputValue });
      } else {
        return voucher;
      }
    });
    setFieldValue("vouchers", updatedVouchers);
  };

  const handleBenefitPredefinedValuesChange = (e, index, value, type) => {
    const updatedVouchers = values.vouchers.map((voucher, i) => {
      if (i === index) {
        return new Voucher({
          ...voucher,
          predefinedValuesOnly:
            type === "predefined" ? value : voucher.predefinedValuesOnly,
          isReimbursement: type === "refund" ? value : voucher.isReimbursement
        });
      } else {
        return voucher;
      }
    });
    setFieldValue("vouchers", updatedVouchers);
  };

  const removeVoucher = voucherId => {
    const updatedVouchers = values.vouchers.filter(
      voucher => voucher.id !== voucherId
    );
    setFieldValue("vouchers", updatedVouchers);
  };

  return (
    <VouchersStyles>
      <TextAndLine title={t("Vouchers")} />
      <div className="voucherContainer">
        <div className="voucherHead">
          <div style={{ width: 381 }}>
            <InputLabel label={t("Options")} customCssClass="labelStyles" />
          </div>
          <div style={{ width: 84 }}>
            <InputLabel label={t("Tokens")} customCssClass="labelStyles" />
          </div>
        </div>
        <div>
          {values.vouchers.map((voucher, i) => (
            <>
              <div style={{ display: "flex", padding: "5px" }}>
                <CheckboxInput
                  isChecked={voucher.predefinedValuesOnly}
                  disabled={voucher.isReimbursement}
                  onChange={e =>
                    handleBenefitPredefinedValuesChange(
                      e,
                      i,
                      !voucher.predefinedValuesOnly,
                      "predefined"
                    )
                  }
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: 24
                  }}
                >
                  <InputLabel
                    customCssClass="labelStyles"
                    margin="0 0 0 10px"
                    label={t("only_predefined_values")}
                    shouldHaveColon={false}
                  />
                </div>
              </div>
              {isAdmin && (
                <div style={{ display: "flex", padding: "5px" }}>
                  <CheckboxInput
                    isChecked={voucher.isReimbursement}
                    disabled={voucher.predefinedValuesOnly}
                    onChange={e =>
                      handleBenefitPredefinedValuesChange(
                        e,
                        i,
                        !voucher.isReimbursement,
                        "refund"
                      )
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: 24
                    }}
                  >
                    <InputLabel
                      customCssClass="labelStyles"
                      margin="0 0 0 10px"
                      label={t("refund_voucher")}
                      shouldHaveColon={false}
                    />
                  </div>
                </div>
              )}
              <div className="voucher" key={i}>
                <div className="voucherNameContainer">
                  <input
                    type="text"
                    name={`voucherName${i}`}
                    value={voucher.name}
                    onChange={e => handleNameChange(e, i)}
                    className="voucherNameInput"
                  />
                </div>
                <div className="voucherPriceContainer">
                  <TokenIcon width="24px" />
                  <input
                    type="text"
                    pattern="[0-9]*"
                    onChange={e => handlePriceChange(e, i)}
                    value={voucher.price}
                    className="voucherPriceInput"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => removeVoucher(voucher.id)}
                  className="removeTierButton"
                >
                  X
                </button>
              </div>
            </>
          ))}
        </div>
        <div style={{ display: "flex", alignItems: "center", height: 14 }}>
          <div className="errorMsg">
            {errors.vouchers && touched.vouchers ? errors.vouchers : ""}
          </div>
        </div>
        <button
          type="button"
          onClick={addNewVoucher}
          className="addVoucherButton"
        >
          +
        </button>
      </div>
    </VouchersStyles>
  );
};

export default Vouchers;
