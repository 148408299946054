import * as actionTypes from "../../constants/actionTypes/hr/benefits.constants";
import {
  BenefitGroup,
  Tier,
  Employee,
  Category,
  Tag
} from "../../models/domain";
import * as modelMapperService from "../../models/modelMapper.service";
import { isEmpty } from "../../services/general.utils";

export const setBenefitGroups = (benefitGroups, count) => {
  const domainBenefitGroups = modelMapperService.mapManyToDomain(
    benefitGroups,
    BenefitGroup
  );
  return {
    type: actionTypes.SET_HR_BENEFIT_GROUPS,
    benefitGroups: domainBenefitGroups,
    count
  };
};

export const setCategories = categories => {
  const domainCategories = modelMapperService.mapManyToDomain(
    categories,
    Category
  );
  return {
    type: actionTypes.SET_HR_CATEGORIES_FILTERS,
    categories: domainCategories
  };
};

export const setTags = tags => {
  const domainTags = !isEmpty(tags)
    ? modelMapperService.mapManyToDomain(tags, Tag)
    : [];
  return {
    type: actionTypes.SET_HR_TAGS_FILTERS,
    tags: domainTags
  };
};

export const setEnabledBenefitGroup = enabledBenefitGroup => {
  return {
    type: actionTypes.SET_HR_ENABLED_BENEFIT_GROUP_FILTERS,
    enabledBenefitGroup: enabledBenefitGroup
  };
};

export const setKeywordSearch = keywordSearch => {
  return {
    type: actionTypes.SET_HR_BENEFITS_KEYWORD_SEARCH,
    keywordSearch: keywordSearch
  };
};

export const setChosenCategories = (chosenCategories, chosenCategoryIds) => {
  const domainCategories = !isEmpty(chosenCategories)
    ? modelMapperService.mapManyToDomain(chosenCategories, Category)
    : [];
  return {
    type: actionTypes.SET_HR_BENEFITS_CHOSEN_CATEGORIES_FILTERS,
    chosenCategories: domainCategories,
    chosenCategoryIds: chosenCategoryIds
  };
};

export const setChosenTags = (chosenBenefitTags, chosenBenefitTagIds) => {
  const domainTags = !isEmpty(chosenBenefitTags)
    ? modelMapperService.mapManyToDomain(chosenBenefitTags, Tag)
    : [];
  return {
    type: actionTypes.SET_HR_BENEFITS_CHOSEN_TAGS_FILTERS,
    chosenBenefitTags: domainTags,
    chosenBenefitTagIds: chosenBenefitTagIds
  };
};

export const resetBenefitFiltersToDefault = () => {
  return {
    type: actionTypes.SET_HR_BENEFITS_FILTERS_TO_DEFAULT
  };
};

export const setPage = page => {
  return { type: actionTypes.SET_HR_BENEFITS_PAGE, page };
};

export const setEditHrBenfitsInitialState = (benefitGroup, tiers) => {
  const domainTiers = modelMapperService.mapManyToDomain(tiers, Tier);
  const domainBenefitGroup = modelMapperService.mapOneToDomain(
    benefitGroup,
    BenefitGroup
  );

  return {
    type: actionTypes.SET_HR_EDIT_BENEFITS_INITIAL_STATE,
    benefitGroup: domainBenefitGroup,
    tiers: domainTiers
  };
};

export const toggleBenefitGroup = isBenefitGroupActive => {
  return {
    type: actionTypes.TOGGLE_BENEFIT_GROUP,
    isBenefitGroupActive
  };
};

export const toggleBenefitGroupInstalments = enableInstalments => {
  return {
    type: actionTypes.TOGGLE_BENEFIT_GROUP_INSTALMENTS,
    enableInstalments
  };
};

export const setHrBenefitGroupUsers = (users, count) => {
  const domainUsers = modelMapperService.mapManyToDomain(users, Employee);
  return {
    type: actionTypes.SET_HR_BENEFIT_GROUP_USERS,
    users: domainUsers,
    count
  };
};

export const setHrBenefitGroupUsersPage = page => {
  return {
    type: actionTypes.SET_HR_BENEFIT_GROUP_USERS_PAGE,
    page
  };
};

export const setHrBenefitGroupCities = payload => {
  return { type: actionTypes.SET_HR_BENEFIT_GROUP_CITIES, payload };
};

export const setHrBenefitGroupChosenCities = payload => {
  return { type: actionTypes.SET_HR_BENEFIT_GROUP_CHOSEN_CITIES, payload };
};

export const setHrBenefitGroupChosenCityIds = payload => {
  return { type: actionTypes.SET_HR_BENEFIT_GROUP_CHOSEN_CITY_IDS, payload };
};
