import React, { useEffect, useState } from "react";
import { Modal } from "../modal";
import BulletinNotificationStyle from "./BulletinNotificationStyle";
import { useSelector } from "react-redux";

export const BulletinNotification = () => {
  const bulletValue = localStorage.getItem("bullet-board-refund-back");
  const [isModalOpen, setIsModalOpen] = useState();

  const { companyId } = useSelector(state => state.app.user);

  useEffect(() => {
    if (!bulletValue) {
      localStorage.setItem("bullet-board-refund-back", true);
      setIsModalOpen(true);
    }
  }, []);

  const closeModal = () => {
    localStorage.setItem("bullet-board-refund-back", false);
    setIsModalOpen(false);
  };

  return (
    <>
      {companyId === 23 && bulletValue === "true" && (
        <Modal closeModal={closeModal}>
          <BulletinNotificationStyle>
            <div className="bulletin-notification">
              <div className="bulletin-notification__header">
                <h3>Benefiti</h3>
              </div>
              <div className="bulletin-notification__body">
                <p>
                  Obaveštavamo vas da je{" "}
                  <b>
                    <u>Refundacija</u>
                  </b>{" "}
                  ponovo aktivna.
                  <br /> <br />
                  Molimo vas da imate u vidu da će se prihvatati isključivo
                  računi iz{" "}
                  <b>
                    <u>2025. </u>
                  </b>
                  godine.
                </p>
              </div>
            </div>
          </BulletinNotificationStyle>
        </Modal>
      )}
    </>
  );
};
