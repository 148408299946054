import React from "react";
import BenefitGroupBenefitPreviewListStyles from "./BenefitGroupBenefitPreviewListStyles";
import { BenefitGroupBenefitPreviewItem } from "./BenefitGroupBenefitPreviewItem";
import { calculatePriceWithVat } from "../../../../../services/benefit.utils";
import { BenefitExpirationTypeEnum } from "../../../../../constants/benefit.constants";
import { useSelector } from "react-redux";
import ListPlaceHolder from "../../../../../components/listPlaceholder/ListPlaceholder";

export const BenefitGroupBenefitPreviewList = props => {
  const { benefitGroup } = props;
  const { user } = useSelector(state => state.app);

  const getBenefitPrice = benefit => {
    if (benefit.isSubscribed) {
      return benefit.subscribedPrice;
    }

    const benefitPrice =
      user.companyVatEnabled && benefit.vat
        ? calculatePriceWithVat(benefit.tokenPrice, benefit.vat)
        : benefit.tokenPrice;

    if (
      benefitGroup.doesBenefitGroupHaveInstalments &&
      benefitGroup.enableInstalments &&
      benefit.expiration === "one time" &&
      benefit.instalments
    ) {
      return `${benefit.instalments} x ${Math.floor(
        benefitPrice / benefit.instalments
      )}`;
    }

    return benefitPrice;
  };

  const getVoucherPrices = benefit => {
    if (benefit.expiration === BenefitExpirationTypeEnum.VOUCHER) {
      const voucherPrices = benefit.vouchers.map(voucher => {
        return user.companyVatEnabled && benefit.vat
          ? calculatePriceWithVat(voucher.price, benefit.vat)
          : voucher.price;
      });
      const maxTokenPrice =
        voucherPrices.length > 0 ? Math.max(...voucherPrices) : 0;
      const minTokenPrice =
        voucherPrices.length > 0 ? Math.min(...voucherPrices) : 0;

      if (maxTokenPrice === minTokenPrice) {
        return `${maxTokenPrice}`;
      } else {
        return `${minTokenPrice} - ${maxTokenPrice}`;
      }
    }
  };

  const listOfBenefitsForPreview = benefitGroup.benefits.filter(
    items => !items.isDraft
  );

  return (
    <BenefitGroupBenefitPreviewListStyles>
      {listOfBenefitsForPreview.length > 0 ? (
        listOfBenefitsForPreview.map(benefit => {
          return (
            <BenefitGroupBenefitPreviewItem
              benefit={benefit}
              key={benefit.id}
              getVoucherPrices={getVoucherPrices}
              getBenefitPrice={getBenefitPrice}
            />
          );
        })
      ) : (
        <ListPlaceHolder text="ben_group_no_ben" />
      )}
    </BenefitGroupBenefitPreviewListStyles>
  );
};
