import styled from "styled-components";

const BenefitGroupBenefitPreviewStyles = styled.section`
  padding: 0 30px;
  margin: 30px 0;
  display: flex;
  justify-content: center;

  .benefitGroupBenefitPreviewWrapper {
    min-width: 100%;
    max-width: 1064px;
  }
`;

export default BenefitGroupBenefitPreviewStyles;
