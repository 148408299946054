import React from "react";

import {
  transformPriceRangeTokensToPriceRangeRsd,
  transformTokenCurrencyIcons
} from "../../../../../utils/transformTokensToRsd";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../../../services/general.utils";
import { doesDescriptionExist } from "../../../../../services/textEditor.utils";

export const BenefitGroupBenefitPreviewItem = props => {
  const { benefit, getVoucherPrices, getBenefitPrice } = props;

  const { t } = useTranslation();

  return (
    <div className="benefitGroupPreviewItemWrapper">
      <div className="benefitGroupPreviewItem">
        <div className="benefitGroupPreviewItemTitleBlock">
          <h4 className="title">{benefit.title}</h4>
          {!isEmpty(benefit.quantity) &&
            !isEmpty(benefit.totalQuantityRemaining) &&
            !benefit.isSubscribed && (
              <div className="remainingQuantity">
                {benefit.totalQuantityRemaining} {t("remaining")}
              </div>
            )}
          {!isEmpty(benefit.quantity) &&
            isEmpty(benefit.totalQuantityRemaining) &&
            !benefit.isSubscribed && (
              <div className="remainingQuantity">
                {benefit.quantity} {t("remaining")}
              </div>
            )}
        </div>
        {doesDescriptionExist(benefit.description) ? (
          <div className="benefitDescription">
            {ReactHtmlParser(benefit.description)}
          </div>
        ) : (
          <p className="benefitSummary">{benefit.summary}</p>
        )}
        <div className="benefitExpirationAndTokens">
          <p className="duration">{t(benefit.expiration)}</p>
          <div className="tokenContainer">
            {transformTokenCurrencyIcons()}
            <p className="tokens">
              {benefit.expiration === "voucher"
                ? transformPriceRangeTokensToPriceRangeRsd(
                    getVoucherPrices(benefit)
                  )
                : transformPriceRangeTokensToPriceRangeRsd(
                    getBenefitPrice(benefit)
                  )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
