import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const calculateTime = () => {
  const now = moment();
  const currentMonth = now.month();
  const currentYear = now.year();

  let targetDate = moment({
    year: currentYear,
    month: currentMonth,
    date: 26
  });

  if (now.isAfter(targetDate)) {
    targetDate = moment({
      year: currentYear,
      month: currentMonth + 1,
      date: 26
    });
  }

  const duration = moment.duration(targetDate.diff(now));
  const days = Math.floor(duration.asDays());

  let time = `${days} d`;

  if (duration.asMinutes() <= 60) {
    time = `${Math.floor(duration.asMinutes())} m`;
  } else if (duration.asHours() <= 24) {
    time = `${Math.floor(duration.asHours())} h`;
  }

  return time;
};

export const TimerForLastDay = () => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(calculateTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTime());
    }, 3600 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span style={{ color: "#757575", fontSize: "12px" }}>
      {timeLeft.includes("h") ||
      Number(timeLeft.replace("d", "").trim()) > 25 ? (
        <>
          {t("time_to_pick_locked")}
          <b>
            <u>
              {" "}
              {moment()
                .add(1, "month")
                .startOf("month")
                .format("DD.MM")}
            </u>
          </b>
        </>
      ) : (
        <>
          {t("time_to_pick")}:{" "}
          <b>
            <u>{timeLeft}</u>
          </b>
        </>
      )}
    </span>
  );
};
